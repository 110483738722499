import React from "react"
import { Link } from "gatsby"
import moment from "moment"
import s from "./newsinfo.module.css"
import vul from "./Img/vul.png"
import service from "./Img/server.png"
export default ({ newsInfo, type }) => {
	const { image, title, datetime, summary } = newsInfo
	const imgSrc = { vul, service }
	return (
		<div className={s.container}>
			<div className={s.imgBox}>
				<img src={image ? image : imgSrc[type]} />
			</div>
			<div className={s.textBox}>
				<h3 className={s.title}>
					<span className={s.link} onClick={() => window.location.href = `/news/${type}/detail/?id=${newsInfo._id}`}>
						{title}
					</span>
				</h3>
				<div className={s.summary}>{summary}</div>
				<div className={s.datetime}>{
					moment(datetime).format('YYYY-MM-DD')
				}</div>
			</div>
		</div>
	)
}
