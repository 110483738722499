import React from "react"
import { Link } from "gatsby"
import moment from "moment"
import s from "./detail.module.css"
import axios from 'axios'

export default ({ newsInfo, link, type, id }) => {
	const [info, setInfo] = React.useState({ current: {} })
	const getHotData = async () => {
		const res = await axios.get(`/api/v2/news/detail?type=${type}&id=${id}`).catch((err) => { console.log(err); return {} })
		if (res.status === 200) {
			setInfo(res.data.data)
		}
	}
	React.useEffect(() => { getHotData() }, [id])
	return (
		<div className={s.container}>
			<p className={s.title}>{info.current.title}</p>
			<div className={s.info}>
				<span>来源：{info.current.author || '知道创宇'}</span>
				<span className={s.time}>
					<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
						<g id="新闻中心" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="新闻详情" transform="translate(-1428.000000, -511.000000)">
								<g id="3.Icon/1.线性/1.基础通用类/时间" transform="translate(1428.000000, 511.000000)">
									<path d="M10,2.5 C14.1421356,2.5 17.5,5.85786438 17.5,10 C17.5,14.1421356 14.1421356,17.5 10,17.5 C5.85786438,17.5 2.5,14.1421356 2.5,10 C2.5,5.85786438 5.85786438,2.5 10,2.5 Z M10,3.75 C6.54822031,3.75 3.75,6.54822031 3.75,10 C3.75,13.4517797 6.54822031,16.25 10,16.25 C13.4517797,16.25 16.25,13.4517797 16.25,10 C16.25,6.54822031 13.4517797,3.75 10,3.75 Z M10.625,5.625 L10.625,9.374375 L14.375,9.375 L14.375,10.625 L9.375,10.625 L9.375,5.625 L10.625,5.625 Z" id="形状" fill="#999EA8"></path>
									<rect id="矩形-copy-61" x="0" y="0" width="20" height="20"></rect>
								</g>
							</g>
						</g>
					</svg>
					{moment(info.current.datetime).format('YYYY-MM-DD')}
				</span>
			</div>
			<div className={s.content} dangerouslySetInnerHTML={{ __html: info.current.content }}>
			</div>
			<div className={s.flip}>
				{info.last?.title && <div className={s.up}>
					<span className={s.link} onClick={() => window.location.href = `/news/${type}/detail/?id=${info.last?._id}`}>上一篇：{info.last?.title}</span>
				</div>}
				{info.next?.title && <div className={s.next}>
					<span className={s.link} onClick={() => window.location.href = `/news/${type}/detail/?id=${info.next?._id}`}>下一篇：{info.next?.title}</span>
				</div>}
			</div>
		</div>
	)
}
