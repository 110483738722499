import React, { Component } from "react"
import { Link } from "gatsby"
import FlexSearch from "flexsearch"
import { graphql } from "gatsby"

import { config } from "../../../config"
import qrCode from "./qrCode.png"

import s from "./search.module.css"

const path = config.path.news
const textIcon = (
	<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
		<g id="新闻中心" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="有搜索结果" transform="translate(-400.000000, -634.000000)">
				<g id="编组-6" transform="translate(384.000000, 618.000000)">
					<g id="3.Icon/1.线性/1.基础通用类/报告" transform="translate(16.000000, 16.000000)">
						<path d="M16.2532723,4.37456385 L16.25,12.5015068 L16.875,12.5015068 C17.220178,12.5015068 17.5,12.7813288 17.5,13.1265068 L17.5,15.2145127 C17.5,16.1272952 16.6950262,16.875 15.703534,16.875 L6.89790576,16.875 C5.90641361,16.875 5,16.0787429 5,15.0979873 L5,8.12499975 L3.125,8.12499975 C2.77982203,8.12499975 2.5,7.84517797 2.5,7.5 L2.5,5 C2.5,3.96446609 3.33946609,3.12499975 4.375,3.12499975 L15.0032723,3.12499975 C15.6936282,3.12456385 16.2532723,3.68420791 16.2532723,4.37456385 Z M5,6.83174435 L5,5 C5,4.65482203 4.72017797,4.375 4.375,4.375 C4.02982203,4.375 3.75,4.65482203 3.75,5 L3.75,6.83174435 C3.75,6.87058616 3.77286585,6.89000706 3.79573171,6.89000706 L4.95426829,6.89000706 C4.9847561,6.89000706 5,6.86087571 5,6.83174435 Z M15.0030233,12.5003096 L15.0030233,4.37472819 L5.62447353,4.37472819 C5.96992993,4.37472819 6.24997768,4.65477593 6.25,5.00023234 L6.25,15.0882768 C6.25,15.3570029 6.54645827,15.6265462 6.87222591,15.6265462 C7.18855834,15.6265462 7.5,15.3570029 7.5,15.0882768 L7.5,13.1253096 C7.5,12.7801316 7.77982203,12.5003096 8.125,12.5003096 L15.0030233,12.5003096 Z M15.6224397,15.6269101 C15.9593233,15.6269101 16.2532902,15.325882 16.2532902,14.9568848 L16.2532902,13.7499872 L8.75243187,13.7499872 L8.75243187,14.8306489 C8.75243187,15.0734102 8.75243187,15.2698801 8.67378235,15.3838158 C8.62134933,15.4597729 8.5333164,15.5408043 8.40968355,15.6269101 L15.6224397,15.6269101 Z M7.5,5.625 L13.75,5.625 L13.75,6.875 L7.5,6.875 L7.5,5.625 Z M7.5,8.125 L13.75,8.125 L13.75,9.375 L7.5,9.375 L7.5,8.125 Z" id="形状" fill="#0B6FD6"></path>
						<rect id="矩形-copy-39" x="0" y="0" width="20" height="20"></rect>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
const searchIcon = (
	<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
		<g id="新闻中心" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="有搜索结果" transform="translate(-690.000000, -456.000000)">
				<rect fill="#FFFFFF" x="0" y="0" width="1920" height="2009"></rect>
				<g id="Group" transform="translate(688.000000, 454.000000)">
					<rect id="Rectangle" fill="#000000" opacity="0" x="0" y="0" width="24" height="24"></rect>
					<path d="M10.513224,18.8228941 C5.96760996,18.8228941 2.26941333,15.1055824 2.26941333,10.5364882 C2.26941333,5.9673941 5.96760996,2.25005845 10.513224,2.25005845 C15.0588381,2.25005845 18.7570347,5.96737013 18.7570347,10.5364643 C18.7570347,15.1055584 15.0588381,18.8228941 10.513224,18.8228941 Z M10.513224,3.41233444 C6.60512173,3.41233444 3.42571734,6.60816766 3.42571734,10.5364643 C3.42571734,14.4647609 6.60509773,17.6605941 10.513224,17.6605941 C14.4213503,17.6605941 17.6007307,14.4647609 17.6007307,10.5364643 C17.6007307,6.60816766 14.4213503,3.41233444 10.513224,3.41233444 Z M20.7619074,21.7499415 L15.4914808,16.5605559 L16.460136,15.6023096 L21.7305627,20.7916952 L20.7619074,21.7499415 Z" id="Shape" fill="#0B6FD6" fill-rule="nonzero"></path>
				</g>
			</g>
		</g>
	</svg>
)

const newsImg =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAFKADAAQAAAABAAAAFAAAAACy3fD9AAAB9ElEQVQ4Ec1TsU4bQRCdOV8cJBMJN2mhMAS7SCLRpIOIn0ChMUiIkkOiS5FDShVFsi2lCBKSyV+kdEmZ6mycFFSIBmTANBDf8GbtXRZHcShQkpX2ZvbNm9nZmTkib7HquSg5ZOJy4AxCa07niag5dU3SMEifC3U8St4z83eDCrVNJD2MR813KkVoUuUvi59stlZSkXzAdKLWi0rxC+jCcF3QC6xLgAtED8iubuOGCiD4B2J5qjrWdl/c42vSRKg4mw13r65+FoRkz/pdVktTmkO3WmxYzEgF7wA4WOy2agMGDI0hsinIEPY3j4NXJycoci4IaN2/PE1l77JWMhxTXBJuo8SvUqE8iJGSYd33ncyrw/Dxkg+O1HMbiemlT/Ixc78aFcwE2elUrpctGdMRK3ZeKfywmCGi2LEDBorF+o8BiDF6rX3GbJUtGSPVy4X5510L/BPpHj23I48Omq1PmOxFYXKpj8pKe7I1UXwTx5xannNEsLcAn3VrpYJWlANeCCVz1qPeS0u2EvU5Pq/MflXex07rM/CGtbmAwnJGKc27nglxpzrzDUTd917uyb6HyRBdCinT+VOG2k3/D3UZ+gGt/uAZIvA89ib2iGfLC0xdFVluayK/yZCPMJSr+NtmmSnBrit5eElKY/j9EJBOh23/7/kGlhi5RMPduQAAAAAASUVORK5CYII="

export default class Search extends Component {
	constructor(props) {
		super(props)
		this.index = new FlexSearch({
			encode: false,
			tokenize: str => {
				return str.replace(/[\x00-\x7F]/g, "").split("")
			},
			doc: { id: "id", field: ["title"] },
			depth: 3,
		})
		this.index.add(this.props.store)
		this.state = {
			query: "",
			results: [],
		}
	}

	handleSearchChange = e => {
		this.setState({ query: e.target.value })
	}

	submitSearch = () => {
		const query = this.state.query
		if (!query || query === "") {
			this.props.onResetSearch()
		} else {
			const results = this.index.search(query)
			this.props.onSearch(query, results)
		}
	}

	render () {
		const { type, recommenNews, detail } = this.props

		return (
			<div className={s.search}>
				<div className={s.inputContainer}>
					<input
						className={s.input}
						type="text"
						placeholder="搜索新闻"
						value={this.state.query}
						onChange={this.handleSearchChange}
					/>
					<span className={s.btn} onClick={this.submitSearch}>
						{searchIcon}
					</span>
				</div>
				<>
					{!detail && <ul className={s.list}>
						<li
							key="vul"
							className={
								type === "vul" ? s.activeLink : s.link1
							}
						>
							<span className={s.linkItem} onClick={() => window.location.href = `${path}/vul/`}>
								漏洞速递
							</span>
						</li>
						<li
							key="service"
							className={
								type === "service" ? s.activeLink : s.link
							}
						>
							<span className={s.linkItem} onClick={() => window.location.href = `${path}/service/`}>
								服务公告
							</span>
						</li>
					</ul>}
					<div className={s.recommendBox}>
						<div className={s.title}>
							{/* <img src={textIcon} className={s.img} /> */}
							{textIcon}
							{/* <Link className={s.linkItem2} to={`${path}/product`}> */}
							<span className={s.text}>热门文章</span>
							{/* </Link> */}
						</div>
						<div>
							{recommenNews && recommenNews.map(
								({ title, _id, type, summary }) => (
									<div key={_id} className={s.newsBox}>
										<div className={s.newsTitle}>
											<span className={s.link} onClick={() => window.location.href = `/news/${type}/detail/?id=${_id}`}>
												{title}
											</span>
										</div>
										<div className={s.summary}>
											{summary}
										</div>
									</div>
								)
							)}
						</div>
					</div>
				</>
				<div className={s.infoBox}>
					<img src={qrCode} className={s.img} />
					<div className={s.text}>
						<div>关注云监测公众号</div>
						<div>获取更多信息。</div>
					</div>
				</div>
			</div>
		)
	}
}

export const pageQuery = graphql`
	query {
		allMongodbCloudmonitorNews(limit: 3) {
			edges {
				node {
					title
					summary
					mongodb_id
				}
			}
		}
	}
`
