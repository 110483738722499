import React, { Component } from "react"
import { Link } from "gatsby"
import Pagination from 'rc-pagination';
import NewsInfo from "../../components/NewsInfo"
import Head from "../../components/Head"
import Footer from "../../components/Footer"
import Search from "../../components/Search"
import Detail from "../../components/NewsInfo/Detail"
import SEO from "../../components/seo"
import s from "./list.module.css"
import NoData from "./Img/noData.png"
import { config } from "../../../config"
import 'rc-pagination/assets/index.css'
import axios from 'axios'
const PATH = config.path.news
const searchIcon = (
	<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
		<g id="新闻中心" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="有搜索结果" transform="translate(-690.000000, -456.000000)">
				<rect fill="#FFFFFF" x="0" y="0" width="1920" height="2009"></rect>
				<g id="Group" transform="translate(688.000000, 454.000000)">
					<rect id="Rectangle" fill="#000000" opacity="0" x="0" y="0" width="24" height="24"></rect>
					<path d="M10.513224,18.8228941 C5.96760996,18.8228941 2.26941333,15.1055824 2.26941333,10.5364882 C2.26941333,5.9673941 5.96760996,2.25005845 10.513224,2.25005845 C15.0588381,2.25005845 18.7570347,5.96737013 18.7570347,10.5364643 C18.7570347,15.1055584 15.0588381,18.8228941 10.513224,18.8228941 Z M10.513224,3.41233444 C6.60512173,3.41233444 3.42571734,6.60816766 3.42571734,10.5364643 C3.42571734,14.4647609 6.60509773,17.6605941 10.513224,17.6605941 C14.4213503,17.6605941 17.6007307,14.4647609 17.6007307,10.5364643 C17.6007307,6.60816766 14.4213503,3.41233444 10.513224,3.41233444 Z M20.7619074,21.7499415 L15.4914808,16.5605559 L16.460136,15.6023096 L21.7305627,20.7916952 L20.7619074,21.7499415 Z" id="Shape" fill="#0B6FD6" fill-rule="nonzero"></path>
				</g>
			</g>
		</g>
	</svg>
)
const TASK_KEY_LABEL_MAP = { service: "服务公告", vul: "漏洞速递", product: "文章" }
const PATH_MAP = {
	all: `${PATH}/`,
	service: `${PATH}/service/`,
	vul: `${PATH}/vul/`,
	product: `${PATH}/product/`,
}
export default class BlogList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pageTotal: 0,
			pageInfo: { page: 1, limit: 8 },
			hotData: [],
			pyData: [],
			search: "",
			loading: false
		}
	}

	handleSearch = (search, data) => {
		this.setState({ search })
		this.getData({search})
	}
	handleUnSearch = () => {
		this.setState({ search: '' })
		this.getData({ search: '' })
	}
	getHotData = async () => {
		const hotData = await axios.get(`/api/v2/news/list?isRecommendDisplay=1&page=1&limit=3`).catch((err) => { console.log(err); return {} })
		this.setState({ hotData: hotData?.data?.data?.data })
	}
	getData = async ({search,page,limit}) => {
		this.setState({ loading: true, total: 0 })
		const type = this.props.pageContext.filter.type.eq
		let pageInfo ={}
		if(page){
			pageInfo={page,limit}
		}else{
			pageInfo =this.state.pageInfo
		}
		let url = `/api/v2/news/list?type=${type}&page=${pageInfo.page}&limit=${pageInfo.limit}`
		search && (url += `&title=${search}`)
		const res = await axios.get(url).catch((err) => { console.log(err); return {} })
		const data = res?.data?.data
		this.setState({ pyData: data.data, pageInfo: { page: data.page, limit: data.limit }, total: data.total, loading: false })
	}
	reightDom = (type, detail, pyData) => {
		if (this.state.loading) return <div></div>
		if (detail) {
			let search = this.props?.location?.search
			search && (search = this.props.location.search.replace('?id=', ''))
			return <Detail id={search} type={type} />
		} else {
			if (pyData[0]) {
				return <div className={s.newsList}>
					{pyData.map(node => (
						<NewsInfo
							type={type}
							detail={detail}
							key={node.id}
							newsInfo={node}
						/>
					))}
				</div>
			} else {
				return <div className={s.empty}>
					<img src={NoData} />
				</div>
			}

		}
	}
	linkDome = () => {
		const type = this.props.pageContext.filter.type.eq
		const detail = this.props.pageContext.filter.detail
		const text = TASK_KEY_LABEL_MAP[this.props.pageContext.filter.type.eq]
		if (detail) {
			return (<><span onClick={() => window.location.href = '/'} className={s.home}><span>首页</span></span>&nbsp;>&nbsp;<span onClick={() => window.location.href = `/news/${type}/`} className={s.home}><span>{text}</span></span>&nbsp;>&nbsp;详情</>)
		} else {
			return (<><span onClick={() => window.location.href = '/'} className={s.home}><span>首页</span></span>&nbsp;>&nbsp;{text}</>)
		}
	}
	componentDidMount = async () => {
		this.getData({search:''})
		this.getHotData()
	}

	render () {
		const { pageInfo, pyData, total, search, loading } = this.state
		const { hotData } = this.state
		const {
			pageContext: { filter },
		} = this.props
		const type = filter.type ? filter.type.eq : "vul"
		const detail_ = filter.detail
		return (
			<div>
				<Head />
				<SEO
					title="知道创宇ScanV | 漏洞速递_服务公告"
					description="ScanV 是一款对企事业单位业务系统全生命周期、持续性、多维度监测的新一代云监测产品，为客户提供业务系统脆弱性监测、安全事件监测、业务系统资产发现、可用性监测、内容违规监测、IPv6合规监测等多项能力，帮助客户全面掌握业务系统风险态势。"
					keywords="漏洞监测、SSL监测、可用性监测、安全事件监测、内容违规监测、IPv6合规监测、ScanV、扫描检测、资产发现"
				/>
				<div className={s.tBox}>
						<p className={s.text}>
							<span>知安全动态</span>
							<span>道监测之术</span>
						</p>
				</div>
				<p className={s.crumbs}>{this.linkDome()}</p>
				<div className={s.content}>
						<div className={s.leftContainer}>
							<Search
								onSearch={this.handleSearch}
								onResetSearch={this.handleUnSearch}
								className={s.search}
								type={filter?.type?.eq || "vul"}
								showRecommend={true}
								recommenNews={hotData}
							/>
						</div>
						<div className={s.rightContainer}>
							{search && <div className={s.searchBox}>
								{searchIcon}
								含“<span className={s.searchKey}>{search}</span>”的搜索结果约{total}条
							</div>}
							{this.reightDom(type, detail_, pyData)}
							{(total > 8 && !detail_) && <div className={s.pageBox}> <Pagination
								className={s.pa}
								current={pageInfo.page}
								pageSize={8}
								total={total}
								onChange={(c, p) => {
									this.setState({ page: c, limit: p })
									this.getData({ page: c, limit: p })
								}}
								showQuickJumper
							/></div>}
						</div>
				</div>
				<Footer />
			</div>
		)
	}
}
