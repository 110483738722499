import React, { Component } from "react"
import { Link } from "gatsby"
import s from "./footer.module.css"
import { qrCode } from "../../../config"
export default () => {
	const [userName, setUserName] = React.useState();

  return (
		<footer className={s.footer}>
					<div className={s.info}>
						<div className={s.connect}>
							<div className={s.title}>联系方式</div>
							<div className={s.row}>
								<div className={s.label}>QQ客服</div>
								<div className={s.detail}>4000600990</div>
							</div>
							<div className={s.row}>
								<div className={s.label}>客服热线</div>
								<div className={s.detail}>4001610866</div>
							</div>
							<div className={s.row}>
								<div className={s.label}>投诉邮箱</div>
								<div className={s.detail}>jubao@knownsec.com</div>
							</div>
						</div>
						<div className={s.about}>
							<div className={s.title}>关于</div>
							<div className={s.row}>
								<a
									href="https://www.yunaq.com/overview"
									target="_blank"
									rel="noopener noreferrer"
								>
									关于我们
								</a>
							</div>
							<div className={s.row}>
								<a
									href="https://www.yunaq.com/contact"
									target="_blank"
									rel="noopener noreferrer"
								>
									联系我们
								</a>
							</div>
						</div>
						<div className={s.link}>
							<div className={s.title}>链接</div>
							<div className={s.row}>
								<a
									href="https://www.knownsec.com/#/"
									target="_blank"
									rel="noopener noreferrer"
								>
									知道创宇
								</a>
							</div>
							<div className={s.row}>
								<a
									href="https://www.yunaq.com/"
									target="_blank"
									rel="noopener noreferrer"
								>
									知道创宇云防御
								</a>
							</div>
						</div>
						<div className={s.code}>
							<div className={s.title}>关注我们</div>
							<img loading="lazy" src={qrCode} className={s.img} />
						</div>
					</div>
					<div className={s.footText}>
						<div>
							Copyright © 知道创宇 All rights reserved.
							<a
								href="https://beian.miit.gov.cn"
								target="_blank"
								rel="noopener noreferrer"
							>
								京ICP备10040895号
							</a>
							|
							<a
								href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502034618"
								target="_blank"
								rel="noopener noreferrer"
							>
								京公网安备11010502034618号
							</a>
							| 《中华人民共和国增值电信业务经营许可证》编号:B1-20160020
							B2-20200545
						</div>
					</div>
				</footer>
  )
}
